const CloseButton = ({color = 'white'}) => {
    return (
        <a href="/" className="cursor-pointer absolute top-0 right-0 mt-[23px] mr-[29px] w-[40px] h-[40px] lg:w-[54px] lg:h-[54px] hover:scale-125 transition-all duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54" fill="none">
                <path d="M40.5 13.5L13.5 40.5" stroke={color} strokeWidth="3" stroke-linecap="round" strokeLinejoin="round"/>
                <path d="M13.5 13.5L40.5 40.5" stroke={color} strokeWidth="3" stroke-linecap="round" strokeLinejoin="round"/>
            </svg>
        </a>
    )
}

export default CloseButton;
