const Icon = ({ icon , width = false, height = false, className }) => {
    if (icon === 'arrow') {
      return (
        <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className}>
          <path d="M1.75 7H12.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7 1.75L12.25 7L7 12.25" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      )
    }

    if (icon === 'tick') {
      return (
        <svg viewBox="0 0 16 14" width={width} height={height} className={className} fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path d="m0 7.622 1.778-2.31L6.4 9.221 13.867.867 16 2.822 6.578 13.133 0 7.623Z" /></svg>
      )
    }


    if (icon === 'facebook') return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 48.605 48.605"><g><path stroke="currentColor" d="M34.094,8.688h4.756V0.005h-8.643c-0.721-0.03-9.51-0.198-11.788,8.489c-0.033,0.091-0.761,2.157-0.761,6.983l-7.903,0.024
            v9.107l7.913-0.023v24.021h12.087v-24h8v-9.131h-8v-2.873C29.755,10.816,30.508,8.688,34.094,8.688z M35.755,17.474v5.131h-8v24
            h-8.087V22.579l-7.913,0.023v-5.107l7.934-0.023l-0.021-1.017c-0.104-5.112,0.625-7.262,0.658-7.365
            c1.966-7.482,9.473-7.106,9.795-7.086l6.729,0.002v4.683h-2.756c-4.673,0-6.338,3.054-6.338,5.912v4.873L35.755,17.474
            L35.755,17.474z"/></g>
        </svg>
    )

    if (icon === 'linkedin') return (
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3335 6.6665C14.6596 6.6665 15.9313 7.19329 16.869 8.13097C17.8067 9.06865 18.3335 10.3404 18.3335 11.6665V17.4998H15.0002V11.6665C15.0002 11.2245 14.8246 10.8006 14.512 10.488C14.1994 10.1754 13.7755 9.99984 13.3335 9.99984C12.8915 9.99984 12.4675 10.1754 12.155 10.488C11.8424 10.8006 11.6668 11.2245 11.6668 11.6665V17.4998H8.3335V11.6665C8.3335 10.3404 8.86028 9.06865 9.79796 8.13097C10.7356 7.19329 12.0074 6.6665 13.3335 6.6665V6.6665Z" stroke="CURRENTCOLOR" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.99984 7.5H1.6665V17.5H4.99984V7.5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.33317 4.99984C4.25365 4.99984 4.99984 4.25365 4.99984 3.33317C4.99984 2.4127 4.25365 1.6665 3.33317 1.6665C2.4127 1.6665 1.6665 2.4127 1.6665 3.33317C1.6665 4.25365 2.4127 4.99984 3.33317 4.99984Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )

    if (icon === 'twitter') return (
      <svg  viewBox="0 0 1200 1227" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z" fill="currentColor" />
      </svg>
    )
}

export default Icon;
