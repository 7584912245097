const NavigationArrows = ({showLeftArrow=true, showRightArrow=true, leftLink='/', rightLink='/', color='white'}) => {
    return (
        <>
            <div className="hidden lg:flex flex-row cursor-pointer absolute top-0 right-10 lg:top-[2px] lg:right-20 mt-[23px] mr-[29px] z-30">
                {showLeftArrow &&
                <a href={leftLink}>
                    <svg className="hidden lg:flex hover:scale-125 transition-all duration-300 w-[40px] h-[40px] lg:w-[50px] lg:h-[50px]" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.25 12.5L18.75 25L31.25 37.5" stroke={color} strokeWidth="3" stroke-linecap="round" strokeLinejoin="round"/>
                    </svg>
                    <svg className="flex lg:hidden" width="48" height="95" viewBox="0 0 48 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5" d="M0.499996 5.5531e-06C13.0978 5.00244e-06 25.1796 5.00445 34.0876 13.9124C42.9955 22.8204 48 34.9022 48 47.5C48 60.0978 42.9956 72.1796 34.0876 81.0876C25.1796 89.9956 13.0978 95 0.500008 95L0.499998 47.5L0.499996 5.5531e-06Z" fill="black"/>
                        <path d="M22.5 39L13.5 48L22.5 57" stroke="white" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round"/>
                    </svg>
                </a>
                }
                {showRightArrow &&
                <a href={rightLink}>
                    <svg className="hidden lg:flex hover:scale-125 transition-all duration-300 w-[40px] h-[40px] lg:w-[50px] lg:h-[50px]" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.75 37.5L31.25 25L18.75 12.5" stroke={color} strokeWidth="3" stroke-linecap="round" strokeLinejoin="round"/>
                    </svg>
                    <svg className="flex lg:hidden" width="47" height="95" viewBox="0 0 47 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5" d="M47.5 95C34.9022 95 22.8204 89.9956 13.9124 81.0876C5.00444 72.1796 -2.62696e-06 60.0978 -2.07629e-06 47.5C-1.52562e-06 34.9022 5.00445 22.8204 13.9124 13.9124C22.8204 5.00446 34.9022 6.90465e-06 47.5 5.5531e-06L47.5 47.5L47.5 95Z" fill="black"/>
                        <path d="M24.5 57L33.5 48L24.5 39" stroke="white" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round"/>
                    </svg>
                </a>
                }
            </div>
            <div className="flex lg:hidden flex-row cursor-pointer z-30">
            {showLeftArrow &&
                <a className="absolute -top-12 left-0" href={leftLink}>
                    <svg className="flex lg:hidden" width="48" height="95" viewBox="0 0 48 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5" d="M0.499996 5.5531e-06C13.0978 5.00244e-06 25.1796 5.00445 34.0876 13.9124C42.9955 22.8204 48 34.9022 48 47.5C48 60.0978 42.9956 72.1796 34.0876 81.0876C25.1796 89.9956 13.0978 95 0.500008 95L0.499998 47.5L0.499996 5.5531e-06Z" fill="black"/>
                        <path d="M22.5 39L13.5 48L22.5 57" stroke="white" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round"/>
                    </svg>
                </a>
                }
                {showRightArrow &&
                <a className="absolute -top-12 right-0" href={rightLink}>
                    <svg className="flex lg:hidden" width="47" height="95" viewBox="0 0 47 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.5" d="M47.5 95C34.9022 95 22.8204 89.9956 13.9124 81.0876C5.00444 72.1796 -2.62696e-06 60.0978 -2.07629e-06 47.5C-1.52562e-06 34.9022 5.00445 22.8204 13.9124 13.9124C22.8204 5.00446 34.9022 6.90465e-06 47.5 5.5531e-06L47.5 47.5L47.5 95Z" fill="black"/>
                        <path d="M24.5 57L33.5 48L24.5 39" stroke="white" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round"/>
                    </svg>
                </a>
                }
            </div>
        </>
    )
}

export default NavigationArrows;
