import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from './Icon';

const HoverIcon = ({ greyShade = '', className, active, icon = '' }) => {
  return (
    <div
      className={classNames(
        `w-8 h-8 min-w-[32px] p-2 overflow-hidden rounded-full relative transition-all duration-300 group-hover:md:bg-light_green ${
          className || ''
        }`,
        {
          'bg-light_charcoal': greyShade === 'light',
          'bg-black': greyShade === 'dark' || greyShade === '',
          'bg-light_green': active,
        }
      )}
    >
      <div
        className={classNames(
          'inital-arrow w-8 h-8 p-2 rounded-full bg-light_green absolute flex items-center justify-center text-inherit right-full top-0 transition-all duration-300 group-hover:md:right-0 green-arrow',
          {
            'right-0': active,
            '!text-white': icon,
          }
        )}
      >
        <Icon
          icon={icon === 'tick' ? 'tick' : 'arrow'}
          width={icon === 'tick'? '16' : '12'}
          height={icon === 'tick'? '14' : '12'}
        />
      </div>
      <div
        className={classNames(
          'movement-arrow w-8 h-8 p-2 absolute flex items-center justify-center text-white right-0 top-0 transition-all duration-300 group-hover:md:-right-full',
          {
            '-right-full': active,
          }
        )}
      >
        <Icon
          icon={icon}
          width={icon === 'tick' ? '16' : '12'}
          height={icon === 'tick' ? '14' : '12'}
        />
      </div>
    </div>
  );
};

HoverIcon.propTypes = {
  greyShade: PropTypes.oneOf(['light', 'dark']),
  tickIcon: PropTypes.bool,
};

export default HoverIcon;
