import Source from "../../components/Source";
import InstructionColumn from "../InstructionColumn";
import CloseButton from "../../components/CloseButton";
import Day2Chart from "../../components/Day2Chart";
import DataVisNumber from "../../components/DataVisNumber";
import NavigationArrows from "../../components/NavigationArrows";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";

const DataVisDay2 = () => {
    const alternateContent =
        <p className="text-white pb-12 pr-5">
            <span className="text-clamp-16-33">The record number of lights on a Christmas tree was </span>
            <span className="py-4 green-text-clamp leading-none text-neon_green block font-bold">194,672 </span>
            <span className="text-clamp-16-33">in Belgium in 2010.</span>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn alternateContent={alternateContent}/>
            <div className="w-full lg:min-h-screen bg-[#2EAA8B] relative">
                <DataVisNumber color={'white'} number={2} />
                <Day2Chart />
                <Source textColour="white" link="https://dailyinfographic.com/incredible-christmas-statistics-and-facts/" text="Daily Infographic" />
                <div className="hidden lg:block">
                    <NavigationArrows leftLink={'/day1'} rightLink={'/day3'}/>
                    <CloseButton />
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows leftLink={'/day1'} rightLink={'/day3'}/>
                </div>
            </div>
        </>
    )
}

export default DataVisDay2;
