import { useState } from 'react';
import classNames from 'classnames';

import Icon from './Icon';

const ShareIcon = () => {
	const [sharesOpen, setSharesOpen] = useState(false);

	const path = window.location.href;
	const sharesOpenHandler = () => {
		if (sharesOpen) {
			return setSharesOpen(false);
		}
		return setSharesOpen(true);
	};

    const shareIcons = [
		{
			name: 'facebook',
			openPosition: 'max-md:!right-[38px] md:!left-[-38px]',
			linkBase: `https://www.facebook.com/sharer/sharer.php?u=${path}`,
		},
		{
			name: 'twitter',
			openPosition: 'max-md:!right-[76px] md:!left-[-76px]',
			linkBase: `https://twitter.com/intent/tweet?url=${path}`,
		},
		{
			name: 'linkedin',
			openPosition: 'max-md:!right-[114px] md:!left-[-114px]',
			linkBase: `https://www.linkedin.com/shareArticle?mini=true&url=${path}`,
		},
	];

    return (
      <div onMouseLeave={() => setSharesOpen(false)} className='flex justify-end w-full h-full'>
        <ul className='relative cursor-pointer'>
          <li
            className={classNames(
              'relative z-10 border border-solid border-charcoal rounded-full bg-charcoal flex items-center justify-center text-white hover:text-neon_green transition-colors duration-300',
              {
                '!bg-charcoal !text-neon_green': sharesOpen,
              }
            )}

          >
            <button type="button" aria-label="Click to share" onClick={sharesOpenHandler}>
            <span className='sr-only'>Click to share</span>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="15.5" transform="rotate(-90 16 16)" stroke="currentColor"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M16.8937 12.2837L12.8006 14.3305C12.2531 13.4876 11.3034 12.9298 10.2245 12.9298C8.53007 12.9298 7.1543 14.3056 7.1543 16C7.1543 17.6944 8.53004 19.0702 10.2245 19.0702C11.3034 19.0702 12.2531 18.5124 12.8006 17.6695L16.8937 19.7162C16.7722 20.0468 16.7057 20.4036 16.7057 20.7757C16.7057 22.47 18.0815 23.8459 19.7759 23.8459C21.4703 23.8459 22.846 22.4701 22.846 20.7757C22.846 19.0813 21.4703 17.7056 19.7759 17.7056C18.6969 17.7056 17.7472 18.2633 17.1997 19.1062L13.1067 17.0595C13.2281 16.729 13.2946 16.3721 13.2946 15.9999C13.2946 15.6278 13.2281 15.2709 13.1067 14.9405L17.1997 12.8937C17.7472 13.7366 18.6969 14.2944 19.7759 14.2944C21.4702 14.2944 22.846 12.9186 22.846 11.2242C22.846 9.52979 21.4703 8.15402 19.7759 8.15402C18.0815 8.15402 16.7057 9.52977 16.7057 11.2242C16.7057 11.5963 16.7722 11.9532 16.8937 12.2836V12.2837ZM19.7758 18.3879C21.0935 18.3879 22.1637 19.4579 22.1637 20.7758C22.1637 22.0935 21.0936 23.1636 19.7758 23.1636C18.458 23.1636 17.3879 22.0936 17.3879 20.7758C17.3879 19.458 18.4579 18.3879 19.7758 18.3879ZM10.2245 13.6121C11.5421 13.6121 12.6123 14.6823 12.6123 16C12.6123 17.3178 11.5422 18.3879 10.2245 18.3879C8.9067 18.3879 7.83658 17.3177 7.83658 16C7.83658 14.6823 8.90672 13.6121 10.2245 13.6121ZM19.7758 8.83635C21.0935 8.83635 22.1637 9.90649 22.1637 11.2242C22.1637 12.5419 21.0936 13.6121 19.7758 13.6121C18.458 13.6121 17.3879 12.542 17.3879 11.2242C17.3879 9.90647 18.4579 8.83635 19.7758 8.83635Z" fill="currentColor"/>
                </svg>
            </button>
          </li>
          {shareIcons.map((icon, index) => (
            <li key={`share_icon_${index}`}>
              <a
              key={`share_icon_${index}`}
              href={icon.linkBase}
              target='_blank'
              rel='nofollow noreferrer'
            >
                <div tabIndex={-1}
                  className={classNames(
                    'focus:outline-1 flex flex-col absolute max-md:right-[2px] md:left-[1px] top-[1px] w-[30px] h-[30px] rounded-full border border-white items-center opacity-0 justify-center bg-charcoal hover:text-neon_green hover:border-neon_green transition-all duration-300',
                    {
                      [icon.openPosition]: sharesOpen,
                      '!w-8 !h-8 text-white opacity-100': sharesOpen,
                    }
                  )}
                >
                  <span className='sr-only'>Click to share to {icon.name}</span>
                  <div className="w-5 h-5">
                    <Icon icon={icon.name} />
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
}

export default ShareIcon;

