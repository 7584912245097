const Donation = ({donationMessage, donationLink, donationButtonText}) => {
    return (
        <>            
            <div className="mb-12 lg:mb-[44px] ml-[30px] lg:ml-[57px]">
                {donationMessage ? <p className="text-white w-10/12 lg:w-2/3 mb-5 text-xs lg:text-base">{donationMessage}</p> : ''}
                {donationLink ? 
                    <a className="w-fit block" href={donationLink} target="_blank" rel="noreferrer">
                        <div className="text-sm font-extrabold bg-neon_green hover:opacity-80 transition-all duration-300 w-fit min-w-[212px] h-[50px] text-center items-center justify-center flex uppercase">
                            {donationButtonText ? 
                                <p className="px-7">{donationButtonText}</p>
                            : 
                                <p className="px-7">Donate Here</p>
                            }
                        </div>
                    </a>
                : ''}
            </div>
        </>
    )
}

export default Donation;