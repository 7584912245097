import CloseButton from "../../components/CloseButton";
import DataVisNumber from "../../components/DataVisNumber";
import Day1Chart from "../../components/Day1Chart";
import NavigationArrows from "../../components/NavigationArrows";
import Source from "../../components/Source";
import InstructionColumn from "../InstructionColumn";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";

const DataVisDay1 = () => {


    const alternateContent =
        <p className="pb-12">
            <span className='green-text-clamp font-semibold text-neon_green block break-normal'>227,000</span>
            <div>
            <span className='text-clamp-16-33 font-bold text-neon_green inline'>miles </span>
            <span className="text-clamp-16-33">of wrapping paper is used each year  – a similar distance from the earth to the moon. </span>
            </div>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn alternateContent={alternateContent}/>
            <div className="w-full lg:min-h-screen bg-dark_green relative">
                <DataVisNumber color={'white'} number={1} />
                <Day1Chart />
                <Source
                    textColour="white"
                    link={'https://www.businesswaste.co.uk/christmas-waste/reducing-christmas-wrapping-paper-waste/'}
                    text={'Business Waste'}
                />
                <div className="hidden lg:flex">
                    <NavigationArrows showLeftArrow={false} rightLink={'/day2'} />
                    <CloseButton />
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows showLeftArrow={false} rightLink={'/day2'} />
                </div>
            </div>
        </>
    )
}

export default DataVisDay1;
