import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PageTransition } from '@steveeeie/react-page-transition';
import ReactGA from 'react-ga4';
import { MotionConfig } from 'framer-motion';
import { CookieConsentProvider } from '@use-cookie-consent/react';
import { CookiesProvider } from 'react-cookie';

import DataVisCanvas from './blocks/DataVisCanvas';
import Home from './blocks/Home';
import CookieSettings from './components/CookieSettings';

ReactGA.initialize('G-8BR1PBNVJH');

const App = () => {
  const trackPageview = () => {
    const pagePath = window.location.pathname + window.location.search;
    ReactGA.send({
      hitType: 'pageview',
      page: pagePath,
      title: document.title || '12 stats of Christmas | SOAK Digital Ltd',
    });
  };

  return (
    <>
      <CookieConsentProvider>
        <CookiesProvider>
          <MotionConfig reducedMotion='user'>
            <div className='w-full mx-auto h-full flex flex-col lg:flex-row bg-[#1C1C1C] '>
              <Router onUpdate={trackPageview}>
                <Route
                  render={({ location }) => {
                    return (
                      <PageTransition
                        transitionKey={location.pathname}
                        exitAnimation={'scaleDown'}
                        enterAnimation={'scaleUp'}
                      >
                        <Switch location={location}>
                          <Route exact path='/' component={Home}></Route>
                          <Route
                            path='/day:id'
                            component={DataVisCanvas}
                          ></Route>
                        </Switch>
                      </PageTransition>
                    );
                  }}
                />
              </Router>
            </div>
          </MotionConfig>
          <CookieSettings />
        </CookiesProvider>
      </CookieConsentProvider>
    </>
  );
};

export default App;
