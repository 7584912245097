import InstructionColumn from "../InstructionColumn";
import Source from "../../components/Source";
import CloseButton from "../../components/CloseButton";
import Day8Chart from "../../components/Day8Chart";
import DataVisNumber from "../../components/DataVisNumber";
import NavigationArrows from "../../components/NavigationArrows";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";

const DataVisDay8 = () => {
    const alternateContent =
        <p className="text-white pb-12">
            <span className='green-text-clamp leading-none font-bold text-neon_green inline'>85</span>
            <span className='text-[50px] lg:text-[70px] font-bold text-neon_green inline'>%</span>
            <span className="block text-clamp-16-33">of British adults put up a Christmas tree every year.</span>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn alternateContent={alternateContent}/>
            <div className="w-full lg:min-h-screen bg-[#242424] relative">
                <DataVisNumber color={'white'} number={8} />
                <Day8Chart />
                <Source textColour="white" link="https://moneyzine.com/uk/resources/christmas-statistics-uk/" text="Money Zine"/>
                <div className="hidden lg:block">
                    <NavigationArrows leftLink={'/day7'} rightLink={'/day9'} />
                    <CloseButton />
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows leftLink={'/day7'} rightLink={'/day9'} />
                </div>
            </div>
        </>
    )
}

export default DataVisDay8;
