import SoakLogo from "../components/SoakLogo";
import SnowfallWrapper from '../components/SnowfallWrapper';
import ShareIcon from "../components/ShareIcon";
import Donation from "../components/Donation";
import CloseButton from "../components/CloseButton";

const InstructionColumn = ({heading, content, alternateContent, showSnow = false, donationMessage, donationLink, donationButtonText, showClose = true}) => {
    return (
        <>
            <div className="w-full lg:w-2/6 lg:min-w-[510px] bg-charcoal lg:min-h-screen relative flex flex-col">
                <div className="h-[90px]">
                  <div className="logo-container pt-[30px] w-fit pl-[30px] text-white hover:text-neon_green transition-colors duration-300 z-20">
                      <a href="https://soak.co.uk" target="_blank" rel="noreferrer">
                          <span className="sr-only">SOAK Digital</span>
                          <SoakLogo />
                      </a>
                  </div>
                  <div className="w-3/4 flex justify-end items-centre absolute top-0 right-0 pt-[30px] pr-[30px] z-20">
                      <ShareIcon />
                  </div>
                  {showClose === true ?
                      <div className="w-3/4 flex lg:hidden justify-end items-centre absolute top-[15%] -right-0.5 z-30">
                          <CloseButton />
                      </div>
                  : ''}

                </div>
                <div className="flex flex-col justify-between xl:min-h-[calc(100vh-90px)]">
                  <div className="content-container flex items-center pt-[66px] xl:pt-0 pl-[32px] lg:pl-[60px] pr-[45px] lg:pr-[58px] my-auto text-white z-20 relative">
                    <div className="w-full">
                        {heading ? <h1 className="text-[60px] lg:text-[80px] font-semibold w-full lg:w-11/12 select-none lg:select-auto leading-none">{heading}</h1> : ''}
                        {content ? <p className="mt-5 lg:mt-10 text-lg lg:text-2xl tracking-wider w-full lg:w-11/12 select-none lg:select-auto pb-14"> {content} </p> : ''}
                        {alternateContent ?? ''}
                    </div>
                  </div>

                    {donationMessage || donationLink || donationButtonText ?
                    <div className="mt-12 relative z-10">
                        <Donation donationMessage={donationMessage} donationLink={donationLink} donationButtonText={donationButtonText} />
                    </div>
                    : '' }
                </div>
            </div>
            {showSnow ? <SnowfallWrapper /> : ''}
        </>
    );
}

export default InstructionColumn;
