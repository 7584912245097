import Source from "../../components/Source";
import InstructionColumn from "../InstructionColumn";
import CloseButton from "../../components/CloseButton";
import Day6Chart from "../../components/Day6Chart";
import DataVisNumber from "../../components/DataVisNumber";
import NavigationArrows from "../../components/NavigationArrows";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";

const DataVisDay6 = () => {
    const alternateContent =
        <p className="text-[40px] text-white pb-12">
            <span className="block text-clamp-16-33">Families around the world spend up to </span>
            <span className='green-text-clamp leading-none font-bold text-neon_green inline'>156</span>
            <span className='text-[50px] lg:text-[70px] font-bold text-neon_green inline'>% </span>
            <span className="text-clamp-16-33 block">
                of their monthly income on Christmas.
            </span>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn alternateContent={alternateContent}/>
            <div className="w-full lg:min-h-screen bg-[#242424] relative">
                <DataVisNumber color={'white'} number={6} />
                <Day6Chart />
                <Source
                    textColour="white"
                    link="https://www.prnewswire.com/news-releases/families-around-the-world-spend-156-of-monthly-income-on-christmas---worldremit-data-301680933.html"
                    text="PR Newswire"
                />
                <div className="hidden lg:block">
                    <NavigationArrows leftLink={'/day5'} rightLink={'/day7'}/>
                    <CloseButton />
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows leftLink={'/day5'} rightLink={'/day7'}/>
                </div>
            </div>
        </>
    )
}

export default DataVisDay6;
