import InstructionColumn from "./InstructionColumn";
import Calendar from "./Calendar";
import { useEffect } from "react";

const Home = () => {

  useEffect(() => {
    document.title = `12 stats of Christmas | SOAK Digital Ltd`;
  }, []);

  const alternateContent =
  <div className="text-white pt-12 lg:pt-20">
      <span className='text-[130px] lg:text-[221px] leading-[0] font-bold text-neon_green'>
        12
      </span>
      <div className='text-[50px] xl:text-[60px] 3xl:text-[70px]  font-semibold text-white'>
      stats & facts
      </div>
      <div className="mb-5 text-lg lg:text-2xl white-text-clamp block">
      Full of wonder, delight and tragedy.
      </div>
      <div className="mb-5 text-lg lg:text-2xl white-text-clamp block">
      A broad perspective on seasonal behaviours, trends and struggles.
      </div>
      <div className="text-lg lg:text-2xl white-text-clamp block">
      Extend your support where you <br className="hidden lg:block" /> feel it matters most.
      </div>
      <div className="my-10 w-[100px] h-[1px] bg-white">

      </div>
      <p className="mt-5 lg:mt-10 text-lg lg:text-xl w-full lg:w-11/12 select-none lg:select-auto pb-8">Send us a seasonal message, invite us to your party or share a brief with us.</p>

      <a className="w-fit block mb-5" href="https://soak.co.uk/contact" target="_blank" rel="noreferrer">
        <div className="text-sm font-extrabold bg-neon_green hover:opacity-80 transition-all duration-300 w-fit min-w-[212px] h-[50px] text-center text-black items-center justify-center flex uppercase">
        <p className="px-7">Get In Touch</p>
        </div>
      </a>
    </div>


    return (
        <>
            <div className='w-full bg-white mx-auto h-full overflow-scroll'>
                <div className="flex flex-col lg:flex-row bg-charcoal ">
                    <InstructionColumn
                        alternateContent={alternateContent}
                        showSnow={true}
                        showClose={false}
                    />
                    <Calendar />
                </div>
            </div>
        </>
    )
}

export default Home;
