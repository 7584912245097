import React, { useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';
import { useCookieConsentContext } from '@use-cookie-consent/react';
import { useCookies } from 'react-cookie';
import classNames from 'classnames';

import Button from './Button';
import HoverIcon from './HoverIcon';
import Icon from './Icon';

const CookieSettings = () => {
  const { acceptCookies, consent } = useCookieConsentContext();
  const [cookies, setCookie] = useCookies(['visitedBefore']);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [stripIsOpen, setStripIsOpen] = useState(true);
  const allowNecessary = true;
  const [allowPreferences, setAllowPreferences] = useState(consent.preferences);
  const [allowStatistics, setAllowStatistics] = useState(consent.statistics);
  const [allowMarketing, setAllowMarketing] = useState(consent.marketing);

  useEffect(() => {
    if (cookies.visitedBefore) {
      setStripIsOpen(false);
    }
  }, [cookies]);

  const setCookieHandler = () => {
    setCookie('visitedBefore', 'true', {
      path: '/',
      maxAge: 60 * 60 * 24 * 30 * 1000,
    });
  };

  const acceptAll = () => {
    acceptCookies({
      necessary: allowNecessary,
      preferences: true,
      statistics: true,
      marketing: true,
    });

    setCookieHandler();
    setAllowPreferences(true);
    setAllowStatistics(true);
    setAllowMarketing(true);
    setModalIsOpen(false);
    setStripIsOpen(false);
  };

  return (
    <>
      <button
        onClick={() => setModalIsOpen(true)}
        aria-label='Click to adjust your cookie preferences'
        className={classNames(
          'fixed right-6 bottom-0 rounded-t-md bg-black text-white hover:text-green border border-b-0 border-solid border-t-white border-l-white border-r-white hover:border-t-green hover:border-l-green hover:border-r-green py-2 px-4 text-xs md:text-sm font-semibold shadow z-10 transition-all duration-300 translate-y-full hidden',
          {
            '!block !translate-y-0': !stripIsOpen,
          }
        )}
      >
        Cookie Preferences
      </button>
      <div
        className={classNames(
          'z-[99] fixed left-0 bottom-0 w-full p-4 gap-5 lg:gap-8 bg-black border-t border-solid border-white-transparent text-white flex-col lg:flex-row justify-between items-start lg:items-center transition-all duration-300 translate-y-full hidden',
          {
            '!flex !translate-y-0': stripIsOpen,
          }
        )}
      >
        <div className='text-xs max-w-[710px]'>
          <p>
            We use cookies to give you personalised content and adverts, provide
            you social media content you'll want to see and to analyse our
            traffic. We may share information about how you use the site with
            social media, advertising, and analytics partners.
          </p>
        </div>
        <div className='flex flex-row flex-wrap lg:flex-nowrap items-center gap-3 lg:gap-8 justify-between w-full lg:w-min'>
          <Button
            onClick={() => {
              setModalIsOpen(true);
              setStripIsOpen(false);
            }}
            theme='dark'
            iconClasses='-rotate-90 group-hover:!-translate-y-1 group-hover:!translate-x-0'
            className='whitespace-nowrap !text-xs'
            aria-label='Click to adjust your cookie preferences'
          >
            Adjust Preferences
          </Button>
          <Button
            onClick={acceptAll}
            theme='dark'
            className='ml-auto whitespace-nowrap !text-xs group flex flex-row items-center gap-x-4'
            hideIcon={true}
            aria-label='Click to allow all cookie types'
          >
            Accept all cookies
            <HoverIcon greyShade='light' icon='tick' />
          </Button>
        </div>
      </div>
      {modalIsOpen && (
        <div className='fixed inset-0 flex items-center justify-center overflow-y-auto z-[999]'>
          <div className='fixed inset-0 bg-black bg-opacity-70 w-screen h-screen' />
          <div className='rounded-md max-w-[730px] w-[90%] max-h-[95%] modal-shadow z-20 overflow-y-auto'>
            <div className='bg-white text-black rounded-md p-6 lg:p-12 h-full w-full'>
              <h5 className="font-semibold text-inherit text-[28px] md:!text-[32px] mb-4 md:mb-6">
                Cookie Preferences
              </h5>
              <p className='mb-6 w-full text-14 leading-relaxed'>
                We use cookies to give you personalised content and adverts,
                provide you social media content you'll want to see and to
                analyse our traffic. We may share information about how you use
                the site with social media, advertising, and analytics partners.
              </p>

              <div className='w-full'>
                <div className='w-full flex justify-end mb-4'>
                  <button
                    onClick={acceptAll}
                    className='group flex flex-row items-center gap-x-4'
                    hideIcon={true}
                    aria-label='Click to allow all cookie types'
                  >
                    Accept all cookies
                    <HoverIcon greyShade='light' icon='tick' />
                  </button>
                </div>
                <div className='pt-4 pb-2 text-sm text-gray-500'>
                  <div className='relative text-black'>
                    <ul className='mb-6'>
                      <li className='flex items-center justify-between border-t border-b border-gray py-3'>
                        <div className='w-full pr-4 lg:pr-24'>
                          <p className='mb-2 text-base font-semibold leading-none'>
                            Necessary
                          </p>
                          <span className='block text-14 leading-relaxed'>
                            These cookies are essential for you to browse the
                            website and use its features, such as accessing
                            secure areas of the site.
                          </span>
                        </div>
                        <div className='relative flex items-center justify-center min-w-[32px] min-h-[32px] h-8 w-8 p-2 bg-neon_green text-black rounded-full'>
                          <Icon
                            icon='tick'
                            className='absolute inset-0 m-auto w-1/2'
                          />
                        </div>
                      </li>

                      <li className='flex items-center justify-between border-b border-gray py-3'>
                        <div className='w-full pr-4 lg:pr-24'>
                          <p className='mb-2 text-base font-semibold leading-none'>
                            Functionality
                          </p>
                          <span className='block text-14 leading-relaxed'>
                            These cookies allow a website to remember choices
                            you have made in the past such as what language you
                            prefer.
                          </span>
                        </div>
                        <Switch.Group>
                          <div className='inline-flex flex-row-reverse items-center'>
                            <Switch
                              className={`${
                                allowPreferences ? 'bg-neon_green' : 'bg-peppermint'
                              } relative inline-flex items-center h-6 rounded-full w-11`}
                              onChange={() =>
                                setAllowPreferences(!allowPreferences)
                              }
                              checked={allowPreferences}
                            >
                              <span className='sr-only'>Yes</span>
                              <span
                                className={`${
                                  allowPreferences
                                    ? 'translate-x-6'
                                    : 'translate-x-1'
                                } transition-all duration-300 inline-block w-4 h-4 transform bg-white rounded-full`}
                              />
                            </Switch>
                          </div>
                        </Switch.Group>
                      </li>

                      <li className='flex items-center justify-between border-b border-gray py-3'>
                        <div className='w-full pr-4 lg:pr-24'>
                          <p className='mb-2 text-base font-semibold leading-none'>
                            Performance
                          </p>
                          <span className='block text-14 leading-relaxed'>
                            These cookies collect information about how you use
                            a website, like which pages you visited and which
                            links you clicked on. None of this information can
                            be used to identify you.
                          </span>
                        </div>
                        <Switch.Group>
                          <div className='inline-flex flex-row-reverse items-center'>
                            <Switch
                              className={`${
                                allowStatistics ? 'bg-neon_green' : 'bg-peppermint'
                              } relative inline-flex items-center h-6 rounded-full w-11`}
                              onChange={() =>
                                setAllowStatistics(!allowStatistics)
                              }
                              checked={allowStatistics}
                            >
                              <span className='sr-only'>Yes</span>
                              <span
                                className={`${
                                  allowStatistics
                                    ? 'translate-x-6'
                                    : 'translate-x-1'
                                } transition-all duration-300 inline-block w-4 h-4 transform bg-white rounded-full`}
                              />
                            </Switch>
                          </div>
                        </Switch.Group>
                      </li>

                      <li className='flex items-center justify-between border-b border-gray py-3'>
                        <div className='w-full pr-4 lg:pr-24'>
                          <p className='mb-2 text-base font-semibold leading-none'>
                            Marketing
                          </p>
                          <span className='block text-14 leading-relaxed'>
                            These cookies track your online activity to help
                            advertisers deliver more relevant advertising or to
                            limit how many times you see an ad.
                          </span>
                        </div>
                        <Switch.Group>
                          <div className='inline-flex flex-row-reverse items-center'>
                            <Switch
                              className={`${
                                allowMarketing ? 'bg-neon_green' : 'bg-peppermint'
                              } relative inline-flex items-center h-6 rounded-full w-11`}
                              onChange={() =>
                                setAllowMarketing(!allowMarketing)
                              }
                              checked={allowMarketing}
                            >
                              <span className='sr-only'>Yes</span>
                              <span
                                className={`${
                                  allowMarketing
                                    ? 'translate-x-6'
                                    : 'translate-x-1'
                                } transition-all duration-300 inline-block w-4 h-4 transform bg-white rounded-full`}
                              />
                            </Switch>
                          </div>
                        </Switch.Group>
                      </li>
                    </ul>

                    <div className='flex items-center justify-end'>
                      <Button
                        onClick={() => {
                          acceptCookies({
                            necessary: allowNecessary,
                            preferences: allowPreferences,
                            statistics: allowStatistics,
                            marketing: allowMarketing,
                          });

                          setCookieHandler();
                          setModalIsOpen(false);
                          setStripIsOpen(false);
                        }}
                        aria-label='Click to save your cookie preferences'
                      >
                        Update Preferences
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieSettings;
