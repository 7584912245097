import { Link } from 'react-router-dom';
import { useRef } from 'react';
import DoorGraphics from './DoorGraphics';

const Door = ({number, colour, path, text_colour}) => {
    const closedDoor = useRef();

    return (
        <>
            <div className={`group doorFrame aspect-[3/4] md:aspect-auto relative col-span-1 p-[18px] text-[18px] lg:text-[30px] font-bold  h-full border border-black '`}
                style={{backgroundColor: colour}}>
                <p className="pointer-events-none select-none h-1/6 text-[40px] font-semibold group-hover:scale-110 transition-all duration-300 origin-left" style={{color: text_colour}}>{number}</p>
                <Link to={path}>
                    <span className="sr-only">Door {number}</span>
                    <div className='openDoor absolute top-0 left-0 w-full h-full z-10'></div>
                </Link>
                <div ref={closedDoor} className="closedDoor w-full h-5/6 z-20 pointer-events-none flex flex-col justify-end pb-2 px-2 group-hover:scale-110 transition-all duration-300 origin-center">
                    <DoorGraphics svgType={'door' + number} />
                </div>
            </div>
        </>
    )
}

export default Door;
