import Source from "../../components/Source";
import InstructionColumn from "../InstructionColumn";
import CloseButton from "../../components/CloseButton";
import Day5Chart from "../../components/Day5Chart";
import DataVisNumber from "../../components/DataVisNumber";
import NavigationArrows from "../../components/NavigationArrows";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";

const DataVisDay5 = () => {
    const alternateContent =
        <p className="text-white pb-12">
            <span className='green-text-clamp leading-none font-bold text-neon_green inline'>25</span>
            <span className='text-[50px] lg:text-[70px] font-bold text-neon_green inline'>%</span>
            <span className="text-clamp-16-33 block">
              of the UK’s annual Brussels sprout sales are made in the two weeks before Christmas.
            </span>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn alternateContent={alternateContent}/>
            <div className="w-full lg:min-h-screen bg-neon_green relative">
                <DataVisNumber color={'black'} number={5} />
                <Day5Chart />
                <Source textColour="black" link="https://blog.ons.gov.uk/2018/12/07/the-twelve-stats-of-christmas/" text="Gov.UK"/>
                <div className="hidden lg:block">
                    <NavigationArrows leftLink={'/day4'} rightLink={'/day6'} color='black'/>
                    <CloseButton color='black'/>
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows leftLink={'/day4'} rightLink={'/day6'} color='black'/>
                </div>
            </div>
        </>
    )
}

export default DataVisDay5;
