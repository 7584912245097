import Door from "../components/Door";
const Calendar = () => {

    // Dummy Data for development
    const data = [
        {
        number: 1,
        colour: '#FFFFFF',
        cover: null,
        path: '/day1',
        text_colour: '#1C1C1C',
        },
        {
        number: 2,
        colour: '#2EAA8B',
        cover: null,
        path: '/day2',
        text_colour: '#FFFFFF',
        },
        {
        number: 3,
        colour: '#1C1C1C',
        cover: null,
        path: '/day3',
        text_colour: '#FFFFFF',
        },
        {
        number: 4,
        colour: '#FFFFFF',
        cover: null,
        path: '/day4',
        text_colour: '#1C1C1C',
        },
        {
        number: 5,
        colour: '#36E7BB',
        cover: null,
        path: '/day5',
        text_colour: '#1C1C1C',
        },
        {
        number: 6,
        colour: '#294F41',
        cover: null,
        path: '/day6',
        text_colour: '#FFFFFF',
        },
        {
        number: 7,
        colour: '#2EAA8B',
        cover: null,
        path: '/day7',
        text_colour: '#FFFFFF',
        },
        {
        number: 8,
        colour: '#1C1C1C',
        cover: null,
        path: '/day8',
        text_colour: '#FFFFFF',
        },
        {
        number: 9,
        colour: '#294F41',
        cover: null,
        path: '/day9',
        text_colour: '#FFFFFF',
        },
        {
        number: 10,
        colour: '#1C1C1C',
        cover: null,
        path: '/day10',
        text_colour: '#FFFFFF',
        },
        {
        number: 11,
        colour: '#FFFFFF',
        cover: null,
        path: '/day11',
        text_colour: '#1C1C1C',
        },
        {
        number: 12,
        colour: '#36E7BB',
        cover: null,
        path: '/day12',
        text_colour: '#1C1C1C',
        },
    ]
    return (
        <div className="text-black bg-[white] flex flex-grow">
            <div className="doors-container w-full grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 h-full overflow-scroll lg:overflow-auto">
                {data.map((door, index) => 
                    <Door number={door.number} colour={door.colour} key={'door' + door.number} path={door.path} text_colour={door.text_colour}/>
                )}
            </div>
        </div>
    )
}

export default Calendar;