const SoakLogo = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <g id="logo white">
            <g id="logo">
            <g id="Group 5">
                <path id="Fill 1" fillRule="evenodd" clipRule="evenodd" d="M0.0065918 32.0841V37.0682H22.8313V43.4235H0.0065918V59.8877H27.8785V32.0841H0.0065918ZM4.98904 54.9028H22.81V48.3971H4.98904V54.9028Z" fill="currentColor"/>
                <path id="Fill 2" fillRule="evenodd" clipRule="evenodd" d="M27.8784 -9.15527e-05L0.0064817 0.0021726V16.3893H22.8312V22.8514H-0.00012207V27.8469H27.8784V11.4042H4.98893V4.99538H27.8784V-9.15527e-05Z" fill="currentColor"/>
                <path id="Fill 3" fillRule="evenodd" clipRule="evenodd" d="M32.0574 0.00247192V27.8474H59.9128L59.8972 0.00247192H32.0574ZM37.1262 4.99568H54.8821L54.8896 22.8302H37.1262V4.99568Z" fill="currentColor"/>
                <path id="Fill 4" fillRule="evenodd" clipRule="evenodd" d="M32.0575 32.041V59.8985H37.0933V47.8136L52.4469 59.8953L59.902 59.9019L42.7707 46.0698L59.902 32.0496L52.6952 32.0562L37.1016 44.3704L37.0967 32.041H32.0575Z" fill="currentColor"/>
            </g>
            </g>
            </g>
        </svg>
    )
}
export default SoakLogo;