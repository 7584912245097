import InstructionColumn from "../InstructionColumn";
import Source from "../../components/Source";
import CloseButton from "../../components/CloseButton";
import Day9Chart from '../../components/Day9Chart';
import DataVisNumber from "../../components/DataVisNumber";
import NavigationArrows from "../../components/NavigationArrows";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";

const DataVisDay9 = () => {
    const alternateContent =
        <p className="text-white">
            <span className='green-text-clamp font-bold text-neon_green block'>230,000 </span>
            <span className='text-clamp-16-33 font-bold text-neon_green inline'>households </span>
            <span className="text-clamp-16-33">are experiencing homelessness this Christmas.</span>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn
                alternateContent={alternateContent}
                donationMessage={'Donate to help reduce people struggling with homelessness this Christmas:'}
                donationLink={'https://www.shelter.org.uk/'}
                donationButtonText={'Donate to Shelter'}
            />
            <div className="w-full lg:min-h-screen bg-dark_green relative">
                <DataVisNumber color={'white'} number={9} />
                <Day9Chart />
                <Source
                    textColour="white"
                    link="https://www.insidehousing.co.uk/news/thousands-of-children-homeless-on-christmas-day-33684"
                    text="Inside Housing"
                />
                <div className="hidden lg:block">
                    <NavigationArrows leftLink={'/day8'} rightLink={'/day10'} />
                    <CloseButton />
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows leftLink={'/day8'} rightLink={'/day10'} />
                </div>
            </div>
        </>
    )
}

export default DataVisDay9;
