import InstructionColumn from "../InstructionColumn";
import Source from "../../components/Source";
import CloseButton from "../../components/CloseButton";
import ChartLegend from "../../components/ChartLegend";
import Day11Chart from "../../components/Day11Chart";
import DataVisNumber from "../../components/DataVisNumber";
import NavigationArrows from "../../components/NavigationArrows";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";

const DataVisDay11 = () => {
    const alternateContent =
        <p className="text-white">
            <span className='green-text-clamp font-bold text-neon_green block'>120 </span>
            <div className="max-w-[320px]">
              <span className="text-clamp-16-33 font-bold text-neon_green">million </span>
              <span className="text-clamp-16-33">trees are cut each Christmas.</span>
            </div>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn
                alternateContent={alternateContent}
                donationMessage={'Donate to plant a tree to lock up carbon and help nature flourish:'}
                donationLink={'https://donate.nationaltrust.org.uk/s/'}
                donationButtonText={'Donate to National Trust'}
            />
            <div className="w-full lg:min-h-screen bg-[#1B735E] relative">
                <DataVisNumber color={'white'} number={11} />
                <ChartLegend />
                <Day11Chart />
                <Source
                    textColour="white"
                    link="https://www.statista.com/chart/26221/share-real-or-artificial-christmas-tree-gcs/#:~:text=There%2C%20the%20home%20of%20the,percent%20in%20the%20United%20Kingdom."
                    text="Statista"
                />
                <div className="hidden lg:block">
                    <NavigationArrows leftLink={'/day10'} rightLink={'/day12'} />
                    <CloseButton />
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows leftLink={'/day10'} rightLink={'/day12'} />
                </div>
            </div>
        </>
    )
}

export default DataVisDay11;
