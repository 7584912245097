const DataVisNumber = ({number, color}) => {
    return (
        <div className="absolute top-0 left-0 w-full h-fit z-20 pointer-events-none text-[24px] lg:text-[40px] mt-[23px] ml-[44px]"
            style={{color: color}}
        >
            {number}
        </div>
    )
}

export default DataVisNumber;