import { HiddenEasterEgg } from 'react-hidden-easter-egg';
import Snowfall from 'react-snowfall';

const SnowfallWrapper = () => {

const snowflake1 = document.createElement('img');
snowflake1.src = './images/snowflake.svg';

const soakSnowfall = document.createElement('img');
soakSnowfall.src = './images/soak-logo-white.png';

const images = [snowflake1];

const hiddenImages = [soakSnowfall];
    return (
      <>
        <Snowfall
          // Changes the snowflake color
          color='#f9f9f9'
          // Applied to the canvas element
          style={{
            background: 'transparent',
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            pointerEvents: 'none',
          }}
          // Controls the number of snowflakes that are created (default 150)
          snowflakeCount={50}
          radius={[5, 20]}
          images={images}
        />
        <Snowfall 
          color='#36E7BB'
          style={{
            background: 'transparent',
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            pointerEvents: 'none',
          }}
          snowflakeCount={15}
          radius={[1.5, 5]}
        />
        <HiddenEasterEgg code={['l', 'e', 't', 'i', 't', 's', 'o', 'a', 'k']}>
          <Snowfall
          // Changes the snowflake color
          color='#f9f9f9'
          // Applied to the canvas element
          style={{
            background: 'transparent',
            position: 'fixed',
            width: '100vw',
            height: '100vh',
            pointerEvents: 'none',
          }}
          // Controls the number of snowflakes that are created (default 150)
          snowflakeCount={50}
          radius={[5, 20]}
          images={hiddenImages} />
        </HiddenEasterEgg>
      </>
    )
}

export default SnowfallWrapper;