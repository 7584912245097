import InstructionColumn from "../InstructionColumn";
import Source from "../../components/Source";
import CloseButton from "../../components/CloseButton";
import Day10Chart from "../../components/Day10Chart";
import DataVisNumber from "../../components/DataVisNumber";
import NavigationArrows from "../../components/NavigationArrows";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";

const DataVisDay10 = () => {
    const alternateContent =
        <p className="text-white pb-12">
            <span className="text-clamp-16-33">The busiest day for Amazon online shopping was December 12 2022 when shoppers ordered</span>
            <div className='py-4 green-text-clamp leading-none font-bold text-neon_green block'>3.6 </div>
            <span className="text-clamp-16-33 font-bold text-neon_green">million </span>
            <span className="text-clamp-16-33">items.</span>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn alternateContent={alternateContent}/>
            <div className="w-full lg:min-h-screen bg-[#242424] relative">
                <DataVisNumber color={'white'} number={10} />
                <Day10Chart />
                <Source
                    textColour="white"
                    link="https://www.telegraph.co.uk/news/uknews/1506498/Amazon-shoppers-ordered-41-Christmas-gifts-every-second.html"
                    text="The Telegraph"
                />
                <div className="hidden lg:block">
                    <NavigationArrows leftLink={'/day9'} rightLink={'/day11'} />
                    <CloseButton />
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows leftLink={'/day9'} rightLink={'/day11'} />
                </div>
            </div>
        </>
    )
}

export default DataVisDay10;
