import Source from "../../components/Source";
import InstructionColumn from "../InstructionColumn";
import CloseButton from "../../components/CloseButton";
import Day3Chart from "../../components/Day3Chart";
import DataVisNumber from "../../components/DataVisNumber";
import NavigationArrows from "../../components/NavigationArrows";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";

const DataVisDay3 = () => {
    const alternateContent =
        <p className="text-white">
            <span className='green-text-clamp leading-none font-bold text-neon_green inline'>26</span>
            <span className='text-[50px] lg:text-[70px] text-neon_green inline font-bold'>% </span>
            <span className="text-clamp-16-33 block">of people say Christmas makes their mental health worse.</span>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn
                alternateContent={alternateContent}
                donationMessage={'Donate to Mind to help those struggling at Christmas'}
                donationLink={'https://www.mind.org.uk/donate'}
                donationButtonText={'Donate to Mind'}
            />
            <div className="w-full lg:min-h-screen bg-[#242424] relative">
                <DataVisNumber color={'white'} number={3} />
                <Day3Chart />
                <Source
                    textColour="white"
                    link="https://yougov.co.uk/health/articles/26954-christmas-harms-mental-health-quarter-brits?redirect_from=%2Ftopics%2Fhealth%2Farticles-reports%2F2019%2F12%2F18%2Fchristmas-harms-mental-health-quarter-brits"
                    text="YouGov"
                />
                <div className="hidden lg:block">
                    <NavigationArrows leftLink={'/day2'} rightLink={'/day4'}/>
                    <CloseButton />
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows leftLink={'/day2'} rightLink={'/day4'}/>
                </div>
            </div>
        </>
    )
}

export default DataVisDay3
