import { motion } from 'framer-motion'

  const Door1SVG = (
        <svg className="w-full max-w-[140px] flex mx-auto overflow-visible" viewBox="0 0 140 192" fill="none" xmlns="http://www.w3.org/2000/svg">
            <motion.path animate={{rotate: [5, -5, 5]}} transition={{duration: 5, repeat: Infinity, ease: 'easeInOut'}} style={{originY: 0}} d="M70.5 51L70.5 0" stroke="#1C1C1C"/>
            <g transform="rotate(180 70 121.5)">
                <motion.ellipse animate={{translateX: [10, -10, 10]}} transition={{duration: 5, repeat: Infinity, ease: 'easeInOut'}} style={{originY: 0}} cx="70" cy="121.5" rx="70" ry="70.5" fill="url(#paint0_linear_219_427-door1)"/>
            </g>
            <defs>
            <linearGradient id="paint0_linear_219_427-door1" x1="23.7821" y1="81.7308" x2="140.681" y2="179.183" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2EAA8B"/>
            <stop offset="1" stopColor="#36E7BB" stopOpacity="0"/>
            </linearGradient>
            </defs>
        </svg>
    )

    const Door2SVG = (
        <svg className="w-full max-w-[190px] mx-auto overflow-visible"  viewBox="0 0 190 191" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M95 52L190 191H0L95 52Z" fill="url(#paint0_linear_219_427-door2)"/>
            <ellipse className="animate-bounce" cx="94.5" cy="26" rx="26.5" ry="26" fill="url(#paint1_linear_219_427-door2)"/>
            <defs>
            <linearGradient id="paint0_linear_219_427-door2" x1="95" y1="191" x2="95" y2="34.387" gradientUnits="userSpaceOnUse">
            <stop stopColor="#294F41"/>
            <stop offset="1" stopColor="#294F41" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_219_427-door2" x1="78.6" y1="8.50909" x2="127.869" y2="52.9315" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            </defs>
        </svg>
    )

    const Door3SVG = (
        <svg className="w-full max-w-[112px] mx-auto" viewBox="0 0 112 191" fill="none" xmlns="http://www.w3.org/2000/svg">
            <motion.ellipse initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 3, delay: 0.5, repeat: 'infinity'}} cx="56" cy="134.5" rx="56" ry="56.5" fill="url(#paint0_linear_219_427-door3)"/>
            <motion.ellipse initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 3, delay: 0.7, repeat: 'infinity'}} cx="56" cy="81.5" rx="42" ry="42.5" fill="url(#paint1_linear_219_427-door3)"/>
            <g transform="rotate(90 55.5 29)">
                <motion.ellipse initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 3, delay: 1, repeat: 'infinity'}} cx="55.5" cy="29" rx="29" ry="29.5"  fill="url(#paint2_linear_219_427-door3)"/>
            </g>
            <defs>
            <linearGradient id="paint0_linear_219_427-door3" x1="56" y1="180.5" x2="75.065" y2="73.3337" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_219_427-door3" x1="33.5" y1="111.5" x2="97.0156" y2="26.7622" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint2_linear_219_427-door3" x1="26.5" y1="29.4403" x2="90.9925" y2="29.4403" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            </defs>
        </svg>
    )

    const Door4SVG = (
        <svg className="w-full max-w-[180px] mx-auto" viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="url(#paint0_linear_219_427-door4)" d="M90 0C90 11.819 87.6721 23.5222 83.1492 34.4415C78.6262 45.3608 71.9969 55.2823 63.6396 63.6396C55.2823 71.9969 45.3608 78.6262 34.4415 83.1492C23.5222 87.6721 11.819 90 -7.62939e-06 90L0 0H90Z"/>
            <motion.path animate={{opacity: [1, 0, 1]}} transition={{duration: 2, delay: 1, repeat: Infinity}} fill="white" d="M90 0C90 11.819 87.6721 23.5222 83.1492 34.4415C78.6262 45.3608 71.9969 55.2823 63.6396 63.6396C55.2823 71.9969 45.3608 78.6262 34.4415 83.1492C23.5222 87.6721 11.819 90 -7.62939e-06 90L0 0H90Z"/>

            <path fill="url(#paint1_linear_219_427-door4)" d="M180 90C168.181 90 156.478 87.6721 145.558 83.1492C134.639 78.6262 124.718 71.9969 116.36 63.6396C108.003 55.2823 101.374 45.3608 96.8509 34.4415C92.3279 23.5222 90 11.819 90 -7.86805e-06L180 3.69537e-06L180 90Z"/>
            <motion.path animate={{opacity: [1, 0, 1]}} transition={{duration: 2, delay: 1, repeat: Infinity}} fill="white" d="M180 90C168.181 90 156.478 87.6721 145.558 83.1492C134.639 78.6262 124.718 71.9969 116.36 63.6396C108.003 55.2823 101.374 45.3608 96.8509 34.4415C92.3279 23.5222 90 11.819 90 -7.86805e-06L180 3.69537e-06L180 90Z"/>

            <path fill="url(#paint2_linear_219_427-door4)" d="M90 180C90 168.181 92.3279 156.478 96.8508 145.558C101.374 134.639 108.003 124.718 116.36 116.36C124.718 108.003 134.639 101.374 145.558 96.8508C156.478 92.3279 168.181 90 180 90L180 180L90 180Z"/>
            <motion.path animate={{opacity: [1, 0, 1]}} transition={{duration: 2, delay: 1, repeat: Infinity}} fill="white" d="M90 180C90 168.181 92.3279 156.478 96.8508 145.558C101.374 134.639 108.003 124.718 116.36 116.36C124.718 108.003 134.639 101.374 145.558 96.8508C156.478 92.3279 168.181 90 180 90L180 180L90 180Z"/>

            <path fill="url(#paint3_linear_219_427-door4)" d="M9.34175e-07 90C11.819 90 23.5222 92.3279 34.4415 96.8508C45.3608 101.374 55.2823 108.003 63.6396 116.36C71.9969 124.718 78.6262 134.639 83.1492 145.558C87.6721 156.478 90 168.181 90 180L4.67088e-07 180L9.34175e-07 90Z"/>
            <motion.path animate={{opacity: [1, 0, 1]}} transition={{duration: 2, delay: 1, repeat: Infinity}} fill="white" d="M9.34175e-07 90C11.819 90 23.5222 92.3279 34.4415 96.8508C45.3608 101.374 55.2823 108.003 63.6396 116.36C71.9969 124.718 78.6262 134.639 83.1492 145.558C87.6721 156.478 90 168.181 90 180L4.67088e-07 180L9.34175e-07 90Z"/>

            <motion.path fill="white" animate={{opacity: [1, 0, 1]}} transition={{duration: 2, repeat: Infinity}} fillRule="evenodd" clipRule="evenodd" d="M90 0C90 11.819 87.6721 23.5222 83.1492 34.4415C78.6262 45.3608 71.9968 55.2823 63.6396 63.6396C55.2822 71.9968 45.3608 78.6262 34.4414 83.1492C23.5222 87.6721 11.8188 90 0 90C11.8188 90 23.5222 92.3279 34.4414 96.8508C45.3608 101.374 55.2822 108.003 63.6396 116.36C71.9968 124.718 78.6262 134.639 83.1492 145.558C87.6721 156.478 90 168.181 90 180C90 168.181 92.3279 156.478 96.8508 145.558C101.374 134.639 108.003 124.718 116.36 116.36C124.718 108.003 134.639 101.374 145.558 96.8508C156.478 92.3279 168.181 90 180 90C168.181 90 156.478 87.6721 145.558 83.1492C134.639 78.6262 124.718 71.9969 116.36 63.6396C108.003 55.2823 101.374 45.3608 96.8508 34.4415C92.3279 23.5222 90 11.819 90 0Z"/>
            <motion.path fill="url(#paint0_linear_425_223-door4)" animate={{opacity: [0, 1, 0]}} transition={{duration: 2, repeat: Infinity}} fillRule="evenodd" clipRule="evenodd" d="M90 0C90 11.819 87.6721 23.5222 83.1492 34.4415C78.6262 45.3608 71.9968 55.2823 63.6396 63.6396C55.2822 71.9968 45.3608 78.6262 34.4414 83.1492C23.5222 87.6721 11.8188 90 0 90C11.8188 90 23.5222 92.3279 34.4414 96.8508C45.3608 101.374 55.2822 108.003 63.6396 116.36C71.9968 124.718 78.6262 134.639 83.1492 145.558C87.6721 156.478 90 168.181 90 180C90 168.181 92.3279 156.478 96.8508 145.558C101.374 134.639 108.003 124.718 116.36 116.36C124.718 108.003 134.639 101.374 145.558 96.8508C156.478 92.3279 168.181 90 180 90C168.181 90 156.478 87.6721 145.558 83.1492C134.639 78.6262 124.718 71.9969 116.36 63.6396C108.003 55.2823 101.374 45.3608 96.8508 34.4415C92.3279 23.5222 90 11.819 90 0Z"/>

            <defs>
            <linearGradient id="paint0_linear_219_427-door4" x1="6.4373e-06" y1="-18" x2="6.4373e-06" y2="90" gradientUnits="userSpaceOnUse">
            <stop stopColor="#17C095"/>
            <stop offset="1" stopColor="#17C095" stopOpacity="0.06"/>
            </linearGradient>
            <linearGradient id="paint0_linear_425_223-door4" x1="90" y1="0" x2="90" y2="180" gradientUnits="userSpaceOnUse">
                <stop stopColor="#294F41"/>
            <stop offset="1" stopColor="#294F41" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_219_427-door4" x1="206.5" y1="-6.38857e-06" x2="90" y2="-6.62723e-06" gradientUnits="userSpaceOnUse">
            <stop stopColor="#17C095"/>
            <stop offset="1" stopColor="#17C095" stopOpacity="0.11"/>
            </linearGradient>
            <linearGradient id="paint2_linear_219_427-door4" x1="180" y1="206.5" x2="180" y2="90" gradientUnits="userSpaceOnUse">
            <stop stopColor="#17C095"/>
            <stop offset="1" stopColor="#17C095" stopOpacity="0.12"/>
            </linearGradient>
            <linearGradient id="paint3_linear_219_427-door4" x1="-26.5" y1="180" x2="90" y2="180" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2EAA8B"/>
            <stop offset="1" stopColor="#2EAA8B" stopOpacity="0.22"/>
            </linearGradient>
            </defs>
        </svg>
    )

    const Door5SVG = (
        <svg className="w-full max-w-[184px] mx-auto" viewBox="0 0 184 189" fill="none" xmlns="http://www.w3.org/2000/svg">
            <motion.path animate={{rotateY: 180}} transition={{duration: 3, repeat: 'infinity',  repeatDelay: 0}} d="M46 126L92 189H0L46 126Z" fill="url(#paint0_linear_219_684-door5)"/>
            <motion.path animate={{rotateY: 180}} transition={{duration: 3, repeat: 'infinity',  repeatDelay: 0}} d="M138 126L184 189H92L138 126Z" fill="url(#paint1_linear_219_684-door5)"/>
            <motion.path animate={{rotateY: 180}} transition={{duration: 3, repeat: 'infinity',  repeatDelay: 0}} d="M46 63L92 126H0L46 63Z" fill="url(#paint2_linear_219_684-door5)"/>
            <motion.path animate={{rotateY: 180}} transition={{duration: 3, repeat: 'infinity',  repeatDelay: 0}} d="M138 63L184 126H92L138 63Z" fill="url(#paint3_linear_219_684-door5)"/>
            <motion.path animate={{rotateY: 180}} transition={{duration: 3, repeat: 'infinity',  repeatDelay: 0}} d="M92 0L138 63H46L92 0Z" fill="url(#paint4_linear_219_684-door5)"/>
            <defs>
            <linearGradient id="paint0_linear_219_684-door5" x1="46" y1="109.324" x2="46" y2="226.456" gradientUnits="userSpaceOnUse">
            <stop stopColor="#294F41" stopOpacity="0"/>
            <stop offset="1" stopColor="#294F41"/>
            </linearGradient>
            <linearGradient id="paint1_linear_219_684-door5" x1="138" y1="109.324" x2="138" y2="226.456" gradientUnits="userSpaceOnUse">
            <stop stopColor="#294F41" stopOpacity="0"/>
            <stop offset="1" stopColor="#294F41"/>
            </linearGradient>
            <linearGradient id="paint2_linear_219_684-door5" x1="46" y1="46.3235" x2="46" y2="163.456" gradientUnits="userSpaceOnUse">
            <stop stopColor="#294F41" stopOpacity="0"/>
            <stop offset="1" stopColor="#294F41"/>
            </linearGradient>
            <linearGradient id="paint3_linear_219_684-door5" x1="138" y1="46.3235" x2="138" y2="163.456" gradientUnits="userSpaceOnUse">
            <stop stopColor="#294F41" stopOpacity="0"/>
            <stop offset="1" stopColor="#294F41"/>
            </linearGradient>
            <linearGradient id="paint4_linear_219_684-door5" x1="92" y1="-16.6765" x2="92" y2="100.456" gradientUnits="userSpaceOnUse">
            <stop stopColor="#294F41" stopOpacity="0"/>
            <stop offset="1" stopColor="#294F41"/>
            </linearGradient>
            </defs>
        </svg>
    )

    const Door6SVG = (
        <svg className="w-full max-w-[183px] mx-auto" viewBox="0 0 183 135" fill="none" xmlns="http://www.w3.org/2000/svg">

            <g className='bottom-right'>
                <path d="M137 72L183 135H91L137 72Z" fill="url(#paint2_linear_230_190-door6)"/>
            </g>

            <g className='bottom-left'>
                <path d="M46 72L92 135H0L46 72Z" fill="url(#paint5_linear_230_190-door6)"/>
            </g>

            <motion.path animate={{scale: [0, 1, 0]}} transition={{delay: 2, duration: 2, repeat: 'inifinity', repeatDelay: 4}} style={{originY: 1}} d="M137 0L183 63H91L137 0Z" fill="url(#paint0_linear_230_190-door6)"/>
            <motion.path animate={{scale: [0, 1, 0]}} transition={{duration: 6, repeat: 'inifinity', repeatDelay: 0}} style={{originY: 1}} d="M137 36L183 99H91L137 36Z" fill="url(#paint1_linear_230_190-door6)"/>
            <motion.path animate={{scale: [0, 1, 0]}} transition={{delay: 2, duration: 2, repeat: 'inifinity', repeatDelay: 4}} style={{originY: 1}} d="M46 0L92 63H0L46 0Z" fill="url(#paint3_linear_230_190-door6)"/>
            <motion.path animate={{scale: [0, 1, 0]}} transition={{duration: 6, repeat: 'inifinity', repeatDelay: 0}} style={{originY: 1}} d="M46 36L92 99H0L46 36Z" fill="url(#paint4_linear_230_190-door6)"/>

            <defs>
            <linearGradient id="paint0_linear_230_190-door6" x1="136.495" y1="-7.93333" x2="136.925" y2="100.456" gradientUnits="userSpaceOnUse">
            <stop stopColor="#36E7BB" stopOpacity="0.08"/>
            <stop offset="1" stopColor="#36E7BB"/>
            </linearGradient>
            <linearGradient id="paint1_linear_230_190-door6" x1="136.495" y1="28.0667" x2="136.925" y2="136.456" gradientUnits="userSpaceOnUse">
            <stop stopColor="#36E7BB" stopOpacity="0.08"/>
            <stop offset="1" stopColor="#36E7BB"/>
            </linearGradient>
            <linearGradient id="paint2_linear_230_190-door6" x1="136.495" y1="64.0667" x2="136.925" y2="172.456" gradientUnits="userSpaceOnUse">
            <stop stopColor="#36E7BB" stopOpacity="0.08"/>
            <stop offset="1" stopColor="#36E7BB"/>
            </linearGradient>
            <linearGradient id="paint3_linear_230_190-door6" x1="45.4945" y1="-7.93333" x2="45.9253" y2="100.456" gradientUnits="userSpaceOnUse">
            <stop stopColor="#36E7BB" stopOpacity="0.08"/>
            <stop offset="1" stopColor="#36E7BB"/>
            </linearGradient>
            <linearGradient id="paint4_linear_230_190-door6" x1="45.4945" y1="28.0667" x2="45.9253" y2="136.456" gradientUnits="userSpaceOnUse">
            <stop stopColor="#36E7BB" stopOpacity="0.08"/>
            <stop offset="1" stopColor="#36E7BB"/>
            </linearGradient>
            <linearGradient id="paint5_linear_230_190-door6" x1="45.4945" y1="64.0667" x2="45.9253" y2="172.456" gradientUnits="userSpaceOnUse">
            <stop stopColor="#36E7BB" stopOpacity="0.08"/>
            <stop offset="1" stopColor="#36E7BB"/>
            </linearGradient>
            </defs>
        </svg>
    )

    const Door7SVG = (
        <svg className="w-full max-w-[180px] mx-auto" viewBox="0 0 180 176" fill="none" xmlns="http://www.w3.org/2000/svg">
<motion.path initial={{pathLength: 0}} animate={{pathLength: [0, 1, 0]}} transition={{duration: 4, repeat: 'inifinity', repeatDelay: 0, repeatType: 'reverse'}} d="M3.125 5.25L24.375 20.25L45.875 5.25L67.875 21.125L89.25 5.25L111.25 21.125L133 5.25L154.625 21.125L176.375 5.25" stroke="url(#paint0_linear_481_473)" strokeWidth="8"/>
<motion.path initial={{pathLength: 0}} animate={{pathLength: [0, 1, 0]}} transition={{duration: 4, repeat: 'inifinity', repeatDelay: 0, repeatType: 'reverse'}} d="M3.125 30L24.375 45L45.875 30L67.875 45.875L89.25 30L111.25 45.875L133 30L154.625 45.875L176.375 30" stroke="url(#paint1_linear_481_473)" strokeWidth="8"/>
<motion.path initial={{pathLength: 0}} animate={{pathLength: [0, 1, 0]}} transition={{duration: 4, repeat: 'inifinity', repeatDelay: 0, repeatType: 'reverse'}} d="M3.375 54.75L24.625 69.75L46.125 54.75L68.125 70.625L89.5 54.75L111.5 70.625L133.25 54.75L154.875 70.625L176.625 54.75" stroke="url(#paint2_linear_481_473)" strokeWidth="8"/>
<motion.path initial={{pathLength: 0}} animate={{pathLength: [0, 1, 0]}} transition={{duration: 4, repeat: 'inifinity', repeatDelay: 0, repeatType: 'reverse'}} d="M3.375 79.5L24.625 94.5L46.125 79.5L68.125 95.375L89.5 79.5L111.5 95.375L133.25 79.5L154.875 95.375L176.625 79.5" stroke="url(#paint3_linear_481_473)" strokeWidth="8"/>
<motion.path initial={{pathLength: 0}} animate={{pathLength: [0, 1, 0]}} transition={{duration: 4, repeat: 'inifinity', repeatDelay: 0, repeatType: 'reverse'}} d="M3.375 105L24.625 120L46.125 105L68.125 120.875L89.5 105L111.5 120.875L133.25 105L154.875 120.875L176.625 105" stroke="url(#paint4_linear_481_473)" strokeWidth="8"/>
<motion.path initial={{pathLength: 0}} animate={{pathLength: [0, 1, 0]}} transition={{duration: 4, repeat: 'inifinity', repeatDelay: 0, repeatType: 'reverse'}} d="M3.375 129.75L24.625 144.75L46.125 129.75L68.125 145.625L89.5 129.75L111.5 145.625L133.25 129.75L154.875 145.625L176.625 129.75" stroke="url(#paint5_linear_481_473)" strokeWidth="8"/>
<motion.path initial={{pathLength: 0}} animate={{pathLength: [0, 1, 0]}} transition={{duration: 4, repeat: 'inifinity', repeatDelay: 0, repeatType: 'reverse'}} d="M3.25 154.5L24.5 169.5L46 154.5L68 170.375L89.375 154.5L111.375 170.375L133.125 154.5L154.75 170.375L176.5 154.5" stroke="url(#paint6_linear_481_473)" strokeWidth="8"/>
<defs>
<linearGradient id="paint0_linear_481_473" x1="23" y1="21.125" x2="176.375" y2="21.125" gradientUnits="userSpaceOnUse">
<stop stopColor="#1C1C1C"/>
<stop offset="1" stopColor="#2EAA8B"/>
</linearGradient>
<linearGradient id="paint1_linear_481_473" x1="23" y1="45.875" x2="176.375" y2="45.875" gradientUnits="userSpaceOnUse">
<stop stopColor="#1C1C1C"/>
<stop offset="1" stopColor="#2EAA8B"/>
</linearGradient>
<linearGradient id="paint2_linear_481_473" x1="23.25" y1="70.625" x2="176.625" y2="70.625" gradientUnits="userSpaceOnUse">
<stop stopColor="#1C1C1C"/>
<stop offset="1" stopColor="#2EAA8B"/>
</linearGradient>
<linearGradient id="paint3_linear_481_473" x1="23.25" y1="95.375" x2="176.625" y2="95.375" gradientUnits="userSpaceOnUse">
<stop stopColor="#1C1C1C"/>
<stop offset="1" stopColor="#2EAA8B"/>
</linearGradient>
<linearGradient id="paint4_linear_481_473" x1="23.25" y1="120.875" x2="176.625" y2="120.875" gradientUnits="userSpaceOnUse">
<stop stopColor="#1C1C1C"/>
<stop offset="1" stopColor="#2EAA8B"/>
</linearGradient>
<linearGradient id="paint5_linear_481_473" x1="23.25" y1="145.625" x2="176.625" y2="145.625" gradientUnits="userSpaceOnUse">
<stop stopColor="#1C1C1C"/>
<stop offset="1" stopColor="#2EAA8B"/>
</linearGradient>
<linearGradient id="paint6_linear_481_473" x1="23.125" y1="170.375" x2="176.5" y2="170.375" gradientUnits="userSpaceOnUse">
<stop stopColor="#1C1C1C"/>
<stop offset="1" stopColor="#2EAA8B"/>
</linearGradient>
</defs>
        </svg>
    )

    const Door8SVG = (
<svg className="w-full max-w-[185px] mx-auto" viewBox="0 0 100 100">
<linearGradient id="door-8-gradient" gradientTransform="rotate(90)">
  <stop offset="0" stopColor="white" />
  <stop offset="1" stopColor="white" stopOpacity="0" />
</linearGradient>
<g className="door-8-line-group">
  <rect className="door-8-line-1" x="45" y="10" width="10" height="80" fill="url(#door-8-gradient)" />
  <rect className="door-8-line-2" x="45" y="10" width="10" height="80" fill="url(#door-8-gradient)" />
  <rect className="door-8-line-3" x="45" y="10" width="10" height="80" fill="url(#door-8-gradient)" />
  <rect className="door-8-line-4" x="45" y="10" width="10" height="80" fill="url(#door-8-gradient)"/>
</g>
</svg>

    )

    const Door9SVG = (
        <svg className="w-full max-w-[190px] mx-auto overflow-visible" viewBox="0 0 190 189" fill="none" xmlns="http://www.w3.org/2000/svg">
            <motion.circle animate={{rotate: 360, translateY: [0, -5, 0]}} transition={{rotate: {duration: 10, repeat: Infinity}, translateY: {delay: 0, duration: 2, repeat: Infinity, ease: 'easeInOut'}}} cx="29" cy="29" r="29" fill="url(#paint0_linear_219_427-door9)"/>
            <motion.ellipse animate={{rotate: -360, translateY: [0, -5, 0]}} transition={{rotate: {duration: 17, repeat: Infinity}, translateY: {delay: 0.2, duration: 2, repeat: Infinity, ease: 'easeInOut'}}} cx="95" cy="29" rx="29" ry="29" transform="rotate(180 95 29)" fill="url(#paint1_linear_219_427-door9)"/>
            <motion.circle animate={{rotate: 360, translateY: [0, -5, 0]}} transition={{rotate: {duration: 13, repeat: Infinity}, translateY: {delay: 0.4, duration: 2, repeat: Infinity, ease: 'easeInOut'}}} cx="161" cy="29" r="29" fill="url(#paint2_linear_219_427-door9)"/>

            <motion.ellipse animate={{rotate: -360, translateY: [0, -5, 0]}} transition={{rotate: {duration: 18, repeat: Infinity}, translateY: {delay: 0, duration: 2, repeat: Infinity, ease: 'easeInOut'}}} cx="29" cy="94.5" rx="29" ry="28.5" transform="rotate(180 29 94.5)" fill="url(#paint5_linear_219_427-door9)"/>
            <motion.ellipse animate={{rotate: 360, translateY: [0, -5, 0]}} transition={{rotate: {duration: 15, repeat: Infinity}, translateY: {delay: 0.2, duration: 2, repeat: Infinity, ease: 'easeInOut'}}} cx="95" cy="94.5" rx="29" ry="28.5" fill="url(#paint4_linear_219_427-door9)"/>
            <motion.ellipse animate={{rotate: -360, translateY: [0, -5, 0]}} transition={{rotate: {duration: 19, repeat: Infinity}, translateY: {delay: 0.4, duration: 2, repeat: Infinity, ease: 'easeInOut'}}} cx="161" cy="94.5" rx="29" ry="28.5" transform="rotate(180 161 94.5)" fill="url(#paint3_linear_219_427-door9)"/>

            <motion.circle animate={{rotate: 360, translateY: [0, -5, 0]}} transition={{rotate: {duration: 12, repeat: Infinity}, translateY: {delay: 0, duration: 2, repeat: Infinity, ease: 'easeInOut'}}} cx="29" cy="160" r="29" fill="url(#paint6_linear_219_427-door9)"/>
            <motion.ellipse animate={{rotate: -360, translateY: [0, -5, 0]}} transition={{rotate: {duration: 16, repeat: Infinity}, translateY: {delay: 0.2, duration: 2, repeat: Infinity, ease: 'easeInOut'}}} cx="95" cy="160" rx="29" ry="29" transform="rotate(180 95 160)" fill="url(#paint7_linear_219_427-door9)"/>
            <motion.circle animate={{rotate: 360, translateY: [0, -5, 0]}} transition={{rotate: {duration: 14, repeat: Infinity}, translateY: {delay: 0.4, duration: 2, repeat: Infinity, ease: 'easeInOut'}}} cx="161" cy="160" r="29" fill="url(#paint8_linear_219_427-door9)"/>
            <defs>
            <linearGradient id="paint0_linear_219_427-door9" x1="29" y1="0" x2="29" y2="58" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D9D9D9"/>
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_219_427-door9" x1="95" y1="-1.90735e-06" x2="95" y2="58" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D9D9D9"/>
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint2_linear_219_427-door9" x1="161" y1="0" x2="161" y2="58" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D9D9D9"/>
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint3_linear_219_427-door9" x1="161" y1="66" x2="161" y2="123" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D9D9D9"/>
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint4_linear_219_427-door9" x1="95" y1="66" x2="95" y2="123" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D9D9D9"/>
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint5_linear_219_427-door9" x1="29" y1="66" x2="29" y2="123" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D9D9D9"/>
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint6_linear_219_427-door9" x1="29" y1="131" x2="29" y2="189" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D9D9D9"/>
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint7_linear_219_427-door9" x1="95" y1="131" x2="95" y2="189" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D9D9D9"/>
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint8_linear_219_427-door9" x1="161" y1="131" x2="161" y2="189" gradientUnits="userSpaceOnUse">
            <stop stopColor="#D9D9D9"/>
            <stop offset="1" stopColor="#D9D9D9" stopOpacity="0"/>
            </linearGradient>
            </defs>
        </svg>
    )

    const Door10SVG = (
        <svg className="w-full max-w-[187px] mx-auto pb-10" viewBox="0 0 187 135" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g transform="rotate(-45 23.5449 23.1367)">
                <motion.rect animate={{rotateY: [180, 0]}} transition={{duration: 3, repeat: Infinity, ease: 'easeInOut'}} x="23.5449" y="23.1367" width="32.7202" height="32.7202" fill="url(#paint0_linear_219_427-door10)"/>
            </g>
            <g transform="rotate(-45 70 23.1367)">
                <motion.rect animate={{rotateY: [180, 0]}} transition={{duration: 3, repeat: Infinity, ease: 'easeInOut', delay: 0.5}} x="70" y="23.1367" width="32.7202" height="32.7202" fill="url(#paint1_linear_219_427-door10)"/>
            </g>
            <g transform="rotate(-45 117 23.1367)">
                <motion.rect animate={{rotateY: [180, 0]}} transition={{duration: 3, repeat: Infinity, ease: 'easeInOut', delay: 0.75}} x="117" y="23.1367" width="32.7202" height="32.7202" fill="url(#paint2_linear_219_427-door10)"/>
            </g>
            <g transform="rotate(-45 23.5449 111.137)">
                <motion.rect animate={{rotateY: [180, 0]}} transition={{duration: 3, repeat: Infinity, ease: 'easeInOut'}} x="23.5449" y="111.137" width="32.7202" height="32.7202" fill="url(#paint3_linear_219_427-door10)"/>
            </g>
            <g transform="rotate(-45 70 111.137)">
                <motion.rect animate={{rotateY: [180, 0]}} transition={{duration: 3, repeat: Infinity, ease: 'easeInOut', delay: 0.5}} x="70" y="111.137" width="32.7202" height="32.7202" fill="url(#paint4_linear_219_427-door10)"/>
            </g>
            <g transform="rotate(-45 117 111.137)">
                <motion.rect animate={{rotateY: [180, 0]}} transition={{duration: 3, repeat: Infinity, ease: 'easeInOut', delay: 0.75}} x="117" y="111.137" width="32.7202" height="32.7202" fill="url(#paint5_linear_219_427-door10)"/>
            </g>
            <g transform="rotate(135 46.2734 65.1366)">
                <motion.rect animate={{rotateY: [180, 0]}} transition={{duration: 3, repeat: Infinity, ease: 'easeInOut'}} x="46.2734" y="65.1366" width="32.7202" height="32.7202" fill="url(#paint9_linear_219_427-door10)"/>
            </g>
            <g transform="rotate(135 93.2734 65.1366)">
                <motion.rect animate={{rotateY: [180, 0]}} transition={{duration: 3, repeat: Infinity, ease: 'easeInOut', delay: 0.5}} x="93.2734" y="65.1366" width="32.7202" height="32.7202" fill="url(#paint8_linear_219_427-door10)"/>
            </g>
            <g transform="rotate(135 139.729 65.1366)">
                <motion.rect animate={{rotateY: [180, 0]}} transition={{duration: 3, repeat: Infinity, ease: 'easeInOut', delay: 0.75}} x="139.729" y="65.1366" width="32.7202" height="32.7202" fill="url(#paint6_linear_219_427-door10)"/>
            </g>
            <g transform="rotate(135 186.273 65.1366)">
                <motion.rect animate={{rotateY: [180, 0]}} transition={{duration: 3, repeat: Infinity, ease: 'easeInOut', delay: 1}} x="186.273" y="65.1366" width="32.7202" height="32.7202" fill="url(#paint7_linear_219_427-door10)"/>
            </g>

            <defs>
            <linearGradient id="paint0_linear_219_427-door10" x1="41.2876" y1="-1.74024" x2="39.905" y2="55.8569" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint1_linear_219_427-door10" x1="87.7427" y1="-1.74024" x2="86.3601" y2="55.8569" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint2_linear_219_427-door10" x1="134.743" y1="-1.74024" x2="133.36" y2="55.8569" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint3_linear_219_427-door10" x1="41.2876" y1="86.2598" x2="39.905" y2="143.857" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint4_linear_219_427-door10" x1="87.7427" y1="86.2598" x2="86.3601" y2="143.857" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint5_linear_219_427-door10" x1="134.743" y1="86.2598" x2="133.36" y2="143.857" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint6_linear_219_427-door10" x1="157.471" y1="40.2596" x2="156.089" y2="97.8568" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint7_linear_219_427-door10" x1="204.016" y1="40.2596" x2="202.634" y2="97.8568" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint8_linear_219_427-door10" x1="111.016" y1="40.2596" x2="109.634" y2="97.8568" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            <linearGradient id="paint9_linear_219_427-door10" x1="64.0161" y1="40.2596" x2="62.6335" y2="97.8568" gradientUnits="userSpaceOnUse">
            <stop stopColor="white"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
            </linearGradient>
            </defs>
        </svg>
    )

    const Door11SVG = (
        <svg className="w-full max-w-[175px] mx-auto " viewBox="0 0 175 175" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_219_613"  maskUnits="userSpaceOnUse" x="0" y="0" >
            <rect
            height='175px' width='175px' fill="#D9D9D9"/>
            </mask>
            <g mask="url(#mask0_219_613)">
            <motion.path animate={{
                translateX: [-175, 175],
                translateY: [-175, 175]
            }}
            transition={{
                duration: 8,
                repeat: Infinity,
                ease: 'linear'
            }} fillRule="evenodd" clipRule="evenodd" d="M-95.2226 93.2058L95.3298 -97.3466L104.098 -88.5789L-86.4549 101.974L-95.2226 93.2058ZM-78.8562 109.572L111.696 -80.9802L121.048 -71.6279L-69.5039 118.924L-78.8562 109.572ZM128.647 -64.0292L-61.9052 126.523L-53.1375 135.291L137.415 -55.2614L128.647 -64.0292ZM-45.5388 142.89L145.014 -47.6627L153.197 -39.4795L-37.3555 151.073L-45.5388 142.89ZM160.796 -31.8808L-29.7567 158.672L-20.989 167.439L169.563 -23.113L160.796 -31.8808ZM-13.3903 175.038L177.162 -15.5143L186.514 -6.16203L-4.03808 184.39L-13.3903 175.038ZM194.113 1.43667L3.56065 191.989L12.3284 200.757L202.881 10.2044L194.113 1.43667ZM19.9271 208.356L210.48 17.8031L219.247 26.5709L28.6949 217.123L19.9271 208.356ZM226.846 34.1696L36.2936 224.722L45.0613 233.49L235.614 42.9374L226.846 34.1696ZM52.66 241.088L243.212 50.5361L251.98 59.3038L61.4278 249.856L52.66 241.088ZM259.579 66.9025L69.0265 257.455L77.7943 266.223L268.347 75.6703L259.579 66.9025Z" fill="url(#paint0_linear_219_613-door11)"/>

            <g>
            <motion.path animate={{
                translateX: [-175, 175],
                translateY: [-175, 175]
            }}
            transition={{
                duration: 8,
                delay: 3.77,
                repeat: Infinity,
                ease: 'linear'
            }} fillRule="evenodd" clipRule="evenodd" d="M-95.2226 93.2058L95.3298 -97.3466L104.098 -88.5789L-86.4549 101.974L-95.2226 93.2058ZM-78.8562 109.572L111.696 -80.9802L121.048 -71.6279L-69.5039 118.924L-78.8562 109.572ZM128.647 -64.0292L-61.9052 126.523L-53.1375 135.291L137.415 -55.2614L128.647 -64.0292ZM-45.5388 142.89L145.014 -47.6627L153.197 -39.4795L-37.3555 151.073L-45.5388 142.89ZM160.796 -31.8808L-29.7567 158.672L-20.989 167.439L169.563 -23.113L160.796 -31.8808ZM-13.3903 175.038L177.162 -15.5143L186.514 -6.16203L-4.03808 184.39L-13.3903 175.038ZM194.113 1.43667L3.56065 191.989L12.3284 200.757L202.881 10.2044L194.113 1.43667ZM19.9271 208.356L210.48 17.8031L219.247 26.5709L28.6949 217.123L19.9271 208.356ZM226.846 34.1696L36.2936 224.722L45.0613 233.49L235.614 42.9374L226.846 34.1696ZM52.66 241.088L243.212 50.5361L251.98 59.3038L61.4278 249.856L52.66 241.088ZM259.579 66.9025L69.0265 257.455L77.7943 266.223L268.347 75.6703L259.579 66.9025Z" fill="url(#paint0_linear_219_613-door11)"/>
           </g>
            </g>
            <defs>
            <linearGradient id="paint0_linear_219_613-door11" x1="7.0677" y1="4.94385" x2="210.187" y2="208.063" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2EAA8B"/>
            <stop offset="1" stopColor="#2EAA8B" stopOpacity="0"/>
            </linearGradient>
            </defs>
        </svg>
    )

    const Door12SVG = (

        <svg className="w-full max-w-[200px] mx-auto"  viewBox="0 0 200 150">
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(45)">
            <stop offset="0" stopColor="rgba(0, 0, 0, 0)" />
            <stop offset="100" stopColor="rgba(0, 0, 0, 0.5)" />
          </linearGradient>
          <mask id="mask">
            <g transform="translate(0 0)">
              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, delay: 1, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}} points="35,0 35,35 10,35" fill="white" transform="translate(0 0)" />
              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, delay: 1, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}}n points="35,0 35,35 10,35" fill="white" transform="translate(50 0)" />
              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, delay: 1, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}} points="35,0 35,35 10,35" fill="white" transform="translate(100 0)" />
              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, delay: 1, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}} points="35,0 35,35 10,35" fill="white" transform="translate(150 0)" />

              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, delay: 0.5, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}} points="35,0 35,35 10,35" fill="white" transform="translate(0 50)" />
              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, delay: 0.5, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}} points="35,0 35,35 10,35" fill="white" transform="translate(50 50)" />
              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, delay: 0.5, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}} points="35,0 35,35 10,35" fill="white" transform="translate(100 50)" />
              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, delay: 0.5, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}} points="35,0 35,35 10,35" fill="white" transform="translate(150 50)" />

              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}} points="35,0 35,35 10,35" fill="white" transform="translate(0 100)" />
              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}} points="35,0 35,35 10,35" fill="white" transform="translate(50 100)" />
              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}} points="35,0 35,35 10,35" fill="white" transform="translate(100 100)" />
              <motion.polygon initial={{opacity: 0}} animate={{opacity: [0, 1, 0]}} transition={{duration: 6, repeat: Infinity, repeatType: 'mirror', ease: 'easeInOut', repeatDelay: 1}} points="35,0 35,35 10,35" fill="white" transform="translate(150 100)" />
            </g>
          </mask>
        </defs>
        <rect x="0" y="0" width="185" height="177" fill="#36e7bb" />
        <rect x="0" y="0" width="185" height="177" fill="url(#gradient)" mask="url(#mask)" />
      </svg>
    )



    const DoorGraphics = ({svgType}) => {

        const svgMap = {
            'door1': Door1SVG,
            'door2': Door2SVG,
            'door3': Door3SVG,
            'door4': Door4SVG,
            'door5': Door5SVG,
            'door6': Door6SVG,
            'door7': Door7SVG,
            'door8': Door8SVG,
            'door9': Door9SVG,
            'door10': Door10SVG,
            'door11': Door11SVG,
            'door12': Door12SVG,
        };

        const svg = svgMap[svgType] || null;

    return (
        <div>{svg}</div>

    )
}

export default DoorGraphics;
