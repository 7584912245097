import InstructionColumn from "../InstructionColumn";
import Source from "../../components/Source";
import CloseButton from "../../components/CloseButton";
import Day12Chart from "../../components/Day12Chart";
import DataVisNumber from "../../components/DataVisNumber";
import NavigationArrows from "../../components/NavigationArrows";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";


const DataVisDay12 = () => {
    const alternateContent =
        <p className="text-white pb-12">
            <div className='text-[70px] lg:text-[100px] leading-none font-bold text-neon_green block'>3.6 </div>
            <span className="text-clamp-16-33">
              <span className="text-neon_green font-bold">million </span>
              Japanese families get their Christmas meal from none other than Kentucky Fried Chicken.
            </span>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn alternateContent={alternateContent}/>
            <div className="w-full lg:min-h-screen bg-neon_green relative">
                <DataVisNumber color={'black'} number={12} />
                <Day12Chart />
                <Source
                    textColour="black"
                    link="https://www.statista.com/chart/26221/share-real-or-artificial-christmas-tree-gcs/#:~:text=There%2C%20the%20home%20of%20the,percent%20in%20the%20United%20Kingdom."
                    text="Wired"
                />
                <div className="hidden lg:block">
                    <NavigationArrows showRightArrow={false} leftLink={'/day11'} color='black' />
                    <CloseButton color='black'/>
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows showRightArrow={false} leftLink={'/day11'} color='black' />
                </div>
            </div>
        </>
    )
}

export default DataVisDay12;
