import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from './Icon';

const Button = ({
  children,
  className,
  submit,
  iconClasses,
  theme = 'default',
  hideIcon = false,
  ...props
}) => {
  const defaultClasses =
    'w-fit transition-all duration-300 text-sm uppercase font-bold flex flex-row items-center group';

  const classes = classNames(defaultClasses, {
    'text-black hover:text-neon_green': !theme,
    'text-white hover:text-neon_green': theme === 'dark',
    'text-green hover:text-black': theme === 'green',
  });

  if (submit)
    return (
      <button
        className={classNames(classes, {
          [className]: true,
        })}
        {...props}
        type='submit'
      >
        {children}
        {!hideIcon && (
          <Icon
            icon='arrow'
            width='14'
            height='14'
            className={`transition-transform duration-300 ml-5 group-hover:translate-x-1 ${
              iconClasses || ''
            }`}
          />
        )}
      </button>
    );

  return (
    <button
      className={classNames(classes, {
        [className]: true,
      })}
      {...props}
    >
      {children}
      {!hideIcon && (
        <Icon
          icon='arrow'
          width='14'
          height='14'
          className={`transition-transform duration-300 ml-5 group-hover:translate-x-1 ${
            iconClasses || ''
          }`}
        />
      )}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  submit: PropTypes.bool,
  iconClasses: PropTypes.string,
  theme: PropTypes.oneOf(['default', 'white', 'green', 'dark']),
  hideIcon: PropTypes.bool,
};

export default Button;
