import DataVisDay1 from "./DataVis/DataVisDay1";
import DataVisDay2 from "./DataVis/DataVisDay2";
import DataVisDay3 from "./DataVis/DataVisDay3";
import DataVisDay4 from "./DataVis/DataVisDay4";
import DataVisDay5 from "./DataVis/DataVisDay5";
import DataVisDay6 from "./DataVis/DataVisDay6";
import DataVisDay7 from "./DataVis/DataVisDay7";
import DataVisDay8 from "./DataVis/DataVisDay8";
import DataVisDay9 from "./DataVis/DataVisDay9";
import DataVisDay10 from "./DataVis/DataVisDay10";
import DataVisDay11 from "./DataVis/DataVisDay11";
import DataVisDay12 from "./DataVis/DataVisDay12";
import { useEffect } from "react";

const components = {
    DataVisDay1,
    DataVisDay2,
    DataVisDay3,
    DataVisDay4,
    DataVisDay5,
    DataVisDay6,
    DataVisDay7,
    DataVisDay8,
    DataVisDay9,
    DataVisDay10,
    DataVisDay11,
    DataVisDay12
};

const DataVisCanvas = ({match}) => {
  const {
      params: {id},
  } = match;

const DataVisComponent = components["DataVisDay" + id];

return (
        <div className='w-full bg-white mx-auto h-full overflow-y-scroll overflow-x-hidden'>
            <div className='flex flex-col lg:flex-row h-fit min-h-screen'>
                <DataVisComponent />
            </div>
        </div>
    )
}

export default DataVisCanvas;
