import Source from "../../components/Source";
import InstructionColumn from "../InstructionColumn";
import CloseButton from "../../components/CloseButton";
import DataVisNumber from "../../components/DataVisNumber";
import Day7Chart from "../../components/Day7Chart";
import NavigationArrows from "../../components/NavigationArrows";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";

const DataVisDay7 = () => {
    const alternateContent =
        <p className="text-white">
            <span className='green-text-clamp leading-none font-bold text-neon_green inline'>66</span>
            <span className='text-[50px] lg:text-[70px] font-bold text-neon_green inline'>% </span>
            <span className="block text-clamp-16-33 max-w-[320px]">of people admit to buying too much Christmas food that ends up in the bin.</span>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn
                alternateContent={alternateContent}
                donationMessage={'Take your unused food to your local food bank or donate to help stop UK hunger and poverty.'}
                donationLink={'https://www.trusselltrust.org/make-a-donation/'}
                donationButtonText={'Donate to Trussell Trust'}
            />
            <div className="w-full lg:min-h-screen bg-[#2EAA8B] relative">
                <DataVisNumber color={'white'} number={7} />
                <Day7Chart />
                <Source textColour="white" link="https://www.businesswaste.co.uk/christmas-waste-facts-its-not-very-jolly/" text="Business Waste"/>
                <div className="hidden lg:block">
                    <NavigationArrows leftLink={'/day6'} rightLink={'/day8'}/>
                    <CloseButton />
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows leftLink={'/day6'} rightLink={'/day8'}/>
                </div>
            </div>
        </>
    )
}

export default DataVisDay7;
