const ChartLegend = () => {
    return (
        <div className="text-white px-[47px] pt-[80px] md:pt-[113px]">
            <div className="hidden md:flex flex-row pb-[27px] gap-5">
                <div className="flex flex-row">
                    <span className="bg-neon_green w-[20px] h-[20px] mr-[10px]"></span>
                    <span>Real tree</span>
                </div>
                <div className="flex flex-row">
                    <span className="bg-white w-[20px] h-[20px] mr-[10px]"></span>
                    <span>Artificial tree</span>
                </div>
            </div>
            <span className="w-full max-w-[400px] block text-[20px] lg:text-[24px]">Even though artificial trees are still the favourite amongst UK and US households.</span>
        </div>
    )
}

export default ChartLegend;
