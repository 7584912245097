import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

function ArrowKeyNavigation() {
  const [lastKeyPressed, setLastKeyPressed] = useState(null);
  const [isNavigating, setIsNavigating] = useState(false);
  const history = useHistory();
  const match = useRouteMatch('/day:id');

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isNavigating) {
        return; // Ignore key presses while navigating
      }

      if (event.key === 'ArrowRight' && lastKeyPressed !== 'ArrowRight') { // Right arrow key
        navigateDay(true);
      } else if (event.key === 'ArrowLeft' && lastKeyPressed !== 'ArrowLeft') { // Left arrow key
        navigateDay(false);
      }
      setLastKeyPressed(event.key);
    };

    const navigateDay = (increment) => {
      if (match) {
        setIsNavigating(true); // Set navigation in progress
        const currentDay = parseInt(match.params.id);
        const newDay = increment ? currentDay + 1 : currentDay - 1;

        // Ensure that the day number doesn't go below 1 or exceed 12
        const minDay = 1;
        const maxDay = 12;
        const clampedDay = Math.min(Math.max(newDay, minDay), maxDay);

        // Use history.push to navigate without full page reload
        history.push(`/day${clampedDay}`);
      }
    };
    // Add the event listener when the component mounts
    document.addEventListener('keydown', handleKeyDown);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [lastKeyPressed, isNavigating, history, match]);

  useEffect(() => {
    // Reset the isNavigating state after a short delay
    if (isNavigating) {
      const resetIsNavigating = setTimeout(() => {
        setIsNavigating(false);
      }, 1000); // Adjust the timeout duration as needed
      return () => {
        clearTimeout(resetIsNavigating); // Clear the timeout if the component unmounts
      };
    }
  }, [isNavigating]);
}

export default ArrowKeyNavigation;
