import Source from "../../components/Source";
import InstructionColumn from "../InstructionColumn";
import CloseButton from "../../components/CloseButton";
import Day4Chart from "../../components/Day4Chart";
import DataVisNumber from "../../components/DataVisNumber";
import NavigationArrows from "../../components/NavigationArrows";
import ArrowKeyNavigation from "../../utils/ArrowKeyNavigation";

const DataVisDay4 = () => {
    const alternateContent =
        <p className="text-white pb-12">
            <span className="text-clamp-16-33 block">Christmas is spent with an average of </span>
            <div className="mt-5">
              <span className='green-text-clamp leading-none font-bold text-neon_green inline lg:block'>7.3
              <span className='text-clamp-16-33 text-neon_green inline font-bold'> people</span>
              </span>
            </div>
        </p>

    return (
        <>
            <ArrowKeyNavigation />
            <InstructionColumn alternateContent={alternateContent}/>
            <div className="w-full lg:min-h-screen bg-dark_green relative">
                <DataVisNumber color={'white'} number={4} />
                <Day4Chart />
                <Source textColour="white" link="https://tgmresearch.com/christmas2021-global-survey.html" text="TGM Research"/>
                <div className="hidden lg:block">
                    <NavigationArrows leftLink={'/day3'} rightLink={'/day5'}/>
                    <CloseButton />
                </div>
                <div className="flex lg:hidden absolute top-0 w-full">
                    <NavigationArrows leftLink={'/day3'} rightLink={'/day5'}/>
                </div>
            </div>
        </>
    )
}

export default DataVisDay4;
